import IMEX from "./IMEX";
import staticTables from "@/../public/staticTables.json";
import moment from "moment";

export const basic_concepts_devengables = [
	{ name: "Auxilio de Transporte", value: 140606, value_type: "monetary", is_salarial: true, quantity: 0 },
	{ name: "Viatico", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{
		name: "Vacacion",
		value_type: "monetary",
		is_salarial: false,
		quantity: 0,
		value: 0,
		rules: {
			quantity: "max:31|min:0",
		},
	},
	{
		name: "Vacacion Compensada",
		value_type: "monetary",
		is_salarial: false,
		quantity: 0,
		value: 0,
		rules: {
			quantity: "max:31|min:0",
		},
	},
	{ name: "Prima", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Cesantias", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Licencia Paternidad", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{ name: "Licencia Remunerada", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{ name: "Licencia No Remunerada", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{ name: "Bonificacion", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Auxilio", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Huelga Legal", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Compensacion", Value_Type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Bono Epctv", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Bono Epctv Alimentacion", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Comision", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Pago Tercero", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{ name: "Anticipo", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{ name: "Dotacion", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Apoyo Practica", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Teletrabajo", value_type: "monetary", is_salarial: true, quantity: 0, value: 0 },
	{ name: "Bonificacion Retiro", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Indemnizacion", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
	{ name: "Reintegro", value_type: "monetary", is_salarial: false, quantity: 0, value: 0 },
];

export const basic_concepts_deducibles = [
	{ name: "Fondo Subsistencia", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Sindicato", is_salarial: true, value: 0, value_type: "percentage" },
	{ name: "Sancion", is_salarial: false, value: 0, value_type: "monetary" },
	{ name: "Libranza", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Pago Tercero", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Anticipo", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Pension Voluntaria", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Retencion Fuente", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Afc", is_salarial: false, value: 0, value_type: "monetary" },
	{ name: "Cooperativa", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Embargo Fiscal", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Planes Complementarios", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Educacion", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Reintegro", is_salarial: true, value: 0, value_type: "monetary" },
	{ name: "Deuda", is_salarial: true, value: 0, value_type: "monetary" },
];

export const dictionary_dataico = {
	SALARIO_BASICO: "BASICO",
	AUXILIO_DE_TRANSPORTE: "AUXILIO_DE_TRANSPORTE",
	VIATICO: "VIATICO",
	"HORAS_EXTRAS_DIURNAS_ORDINARIA_(25%)": "HORA_EXTRA_DIURNA",
	"HORAS_EXTRAS_NOCTURNAS_ORDINARIA_(75%)": "HORA_EXTRA_NOCTURNA",
	"RECARGO_NOCTURNO_ORDINARIO_(35%)": "HORA_RECARGO_NOCTURNO",
	"TRABAJO_NOCTURNO_EN_DOMINICAL_Y_FESTIVO_(110%)": "HORA_EXTRA_DIURNA_DF",
	"HORAS_EXTRAS_DIURNAS_FESTIVA_(100%)": "HORA_RECARGO_DIURNA_DF",
	"HORAS_EXTRAS_NOCTURNAS_FESTIVA_(150%)": "HORA_EXTRA_NOCTURNA_DF",
	"RECARGO_NOCTURNO_FESTIVO_(175%)": "HORA_RECARGO_NOCTURNO_DF",
	VACACION: "VACACION",
	VACACION_COMPENSADA: "VACACION_COMPENSADA",
	PRIMA: "PRIMA",
	CESANTIAS: "CESANTIAS",
	LICENCIA_PATERNIDAD: "LICENCIA_PATERNIDAD",
	LICENCIA_REMUNERADA: "LICENCIA_REMUNERADA",
	LICENCIA_NO_REMUNERADA: "LICENCIA_NO_REMUNERADA",
	BONIFICACION: "BONIFICACION",
	BONO: "BONIFICACION",
	INCAPACIDAD_ENFERMEDAD_LABORAL: "INCAPACIDAD",
	INCAPACIDAD_ENFERMEDAD_GENERAL: "INCAPACIDAD",
	AUXILIO: "AUXILIO",
	HUELGA_LEGAL: "HUELGA_LEGAL",
	OTRO_CONCEPTO: "OTRO_CONCEPTO",
	COMPENSACION: "COMPENSACION",
	BONO_EPCTV: "BONO_EPCTV",
	BONO_EPCTV_ALIMENTACION: "BONO_EPCTV_ALIMENTACION",
	COMISION: "COMISION",
	PAGO_TERCERO: "PAGO_TERCERO",
	ANTICIPO: "ANTICIPO",
	DOTACION: "DOTACION",
	APOYO_PRACTICA: "APOYO_PRACTICA",
	TELETRABAJO: "TELETRABAJO",
	BONIFICACION_RETIRO: "BONIFICACION_RETIRO",
	INDEMNIZACION: "INDEMNIZACION",
	REINTEGRO: "REINTEGRO",
	"APORTES_SALUD_(4%)": "SALUD",
	"APORTES_PENSION_(4%)": "FONDO_PENSION",
	FONDO_SUBSISTENCIA: "FONDO_SUBSISTENCIA",
	SINDICATO: "SINDICATO",
	SANCION: "SANCION",
	LIBRANZA: "LIBRANZA",
	OTRA_DEDUCCION: "OTRA_DEDUCCION",
	PENSION_VOLUNTARIA: "PENSION_VOLUNTARIA",
	RETENCION_FUENTE: "RETENCION_FUENTE",
	AFC: "AFC",
	COOPERATIVA: "COOPERATIVA",
	EMBARGO_FISCAL: "EMBARGO_FISCAL",
	PLANES_COMPLEMENTARIOS: "PLANES_COMPLEMENTARIOS",
	EDUCACION: "EDUCACION",
	DEUDA: "DEUDA",
	"FONDO_DE_SOLIDARIDAD_PENSIONAL_(1%)": "FONDO_SOLIDARIDAD_PENSIONAL",
};

export const getConceptTotal = (concept) => {
	let total = concept.value * concept.quantity;
	if (concept.description.toLowerCase() === "cesantias") {
		total += (total * 12) / 100;
	}
	return total;
};

export const getDevengables = (concepts, worker, selecteds = []) => {
	let list = concepts.filter((c) => c.active && c.type == "devengado");
	list.push(...basic_concepts_devengables);
	if (worker) {
		list.push({
			name: "Salario Basico",
			value: worker.salary / 30,
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Horas Extras Diurnas Ordinaria (25%)",
			value: Math.round((worker.salary / 240) * 1.25),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Horas Extras Nocturnas Ordinaria (75%)",
			value: Math.round((worker.salary / 240) * 1.75),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Horas Extras Diurnas Festiva (100%)",
			value: Math.round((worker.salary / 240) * 2),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Horas Extras Nocturnas Festiva (150%)",
			value: Math.round((worker.salary / 240) * 2.5),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Recargo Dominical (75%)",
			value: Math.round((worker.salary / 240) * 0.75),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Recargo Nocturno Ordinario (35%)",
			value: Math.round((worker.salary / 240) * 0.35),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Recargo Nocturno Festivo (175%)",
			value: Math.round((worker.salary / 240) * 1.75),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Trabajo nocturno en dominical y festivo (110%)",
			value: Math.round((worker.salary / 240) * 1.1),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Incapacidad Enfermedad General",
			value: Math.round(worker.salary / 30),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Incapacidad Enfermedad Laboral",
			value: Math.round(worker.salary / 30),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Incapacidad por Accidente de Trabajo",
			value: Math.round(worker.salary / 30),
			value_type: "monetary",
			is_salarial: true,
			quantity: 0,
		});
		const existent = selecteds.map((de) => de.description);
		const oneDayOfSalary = [
			"salario basico",
			"licencia",
			"licencia remunerada",
			"licencia no remunerada",
			"licencia paternidad",
			"incapacidad",
			"incapacidad enfermedad general",
			"incapacidad enfermedad laboral",
			"incapacidad por accidente de trabajo",
		];
		list.forEach((de) => {
			if (oneDayOfSalary.includes((de.name || de.description).toLowerCase())) {
				de.value = Math.round(worker.salary / 30);
				de.value_type = "monetary";
				de.is_salarial = true;
			}
		});
		list = list.filter((d) => !existent.includes(d.name || d.description));
	}
	return list.map((d) => ({
		id: d.id || `dynamic-${d.name || d.description}`,
		description: d.name || d.description,
		quantity: d.quantity || 1,
		type: "devengable",
		rules: d.rules,
		value:
			d.value_type == "percentage" ? d.default_value * (worker ? worker.salary : 0) : d.default_value || d.value || 0,
		value_type: d.value_type,
		is_salarial: d.is_salarial,
		percent: d.value_type == "percentage" ? d.default_value || 0 : undefined,
	}));
};

export const getDeducibles = (concepts, worker, selecteds = []) => {
	let list = concepts.filter((c) => c.active && c.type == "deduccion");
	if (worker) {
		const existent = selecteds.map((de) => de.description);
		list.push({
			name: "Aportes Salud (4%)",
			default_value: 4,
			value_type: "percentage",
			percent: 4,
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Aportes Pension (4%)",
			default_value: 4,
			value_type: "percentage",
			percent: 4,
			is_salarial: true,
			quantity: 0,
		});
		list.push({
			name: "Fondo de solidaridad Pensional (1%)",
			default_value: 1,
			value_type: "percentage",
			percent: 1,
			is_salarial: true,
			quantity: 0,
		});
		list = list.filter((d) => !existent.includes(d.name || d.description));
	}
	list.push(...basic_concepts_deducibles);
	return list.map((d) => ({
		id: d.id || `dynamic-${d.name || d.description}`,
		description: d.name || d.description,
		type: "deducible",
		quantity: d.quantity || 1,
		rules: d.rules,
		value:
			d.value_type == "percentage" ? d.default_value * (worker ? worker.salary : 0) : d.default_value || d.value || 0,
		value_type: d.value_type,
		is_salarial: d.is_salarial,
		percent: d.value_type == "percentage" ? d.default_value || 0 : undefined,
	}));
};

export const importFromExcel = async () => {
	const { data } = await IMEX.import("xlsx", "xlsx");
	const nomina = [];
	const workers = [];
	for (const item of data) {
		const tipo_trabajador = staticTables["dian_tipotrabajador"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["TIPO_DE_TRABAJADOR"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const subtipo_trabajador = staticTables["dian_subtipotrabajador"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["SUB_CODIGO"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const tipo_identificacion = staticTables["dian_docidentificacion"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["TIPO_DE_IDENTIFICACION"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const tipo_contrato = staticTables["dian_tipocontrato"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["TIPO_DE_CONTRATO"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const medio_pago = staticTables["dian_mediospago"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["MEDIO_DE_PAGO"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const department = staticTables["dian_departamentos"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["DEPARTAMENTO"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const city = staticTables["dian_municipios"].find(
			(c) =>
				c.name
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase() ===
				`${item["CIUDAD"]}`
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.toUpperCase()
		);
		const worker = {
			first_name: item["PRIMER_NOMBRE"],
			middle_name: item["SEGUNDO_NOMBRE"],
			email: item["CORREO"],
			address: item["DIRECCION"],
			payment_type:
				item["PERIODICIDAD_NOMINA"] == "MENSUAL"
					? "monthly"
					: item["PERIODICIDAD_NOMINA"] == "QUINCENAL"
					? "biweekly"
					: "weekly",
			phone_number: item["TELEFONO"],
			last_name: `${item["APELLIDO"]} ${item["SEGUNDO_APELLIDO"]}`,
			document: item["IDENTIFICACION"],
			code: item["CODIGO_DEL_EMPLEADO"],
			salary: item["SALARIO"],
			tipo_cuenta: item["TIPO_DE_CUENTA"],
			numero_cuenta: item["NUMERO_DE_CUENTA"],
			nombre_banco: item["BANCO"],
			notes: item["NOTA"],
			admission_date: moment(item["FECHA_DE_INICIO"]).format("YYYY-MM-DD"),
			egress_date: item["FECHA_DE_FINALIZACION"]
				? moment(item["FECHA_DE_FINALIZACION"]).format("YYYY-MM-DD")
				: undefined,
			tipo_identificacion: tipo_identificacion ? tipo_identificacion.codigo : "01",
			tipo_trabajador: tipo_trabajador ? tipo_trabajador.codigo : "01",
			subtipo_trabajador: subtipo_trabajador ? subtipo_trabajador.codigo : "00",
			tipo_contrato: tipo_contrato ? tipo_contrato.codigo : "1",
			medio_pago: medio_pago ? medio_pago.codigo : "1",
			departamento: department ? department.code : "11",
			ciudad: city ? city.codigo : "11001",
		};
		const desc = replacefromDataico(`${item["CODIGO"]}`).replaceAll("_", " ");
		const qty = desc.includes("INCAPACIDAD") || desc.includes("BASICO") ? item["DIAS"] : 1;
		const nominaItem = {
			description: desc,
			value: item["VALOR_SALARIAL"] ? item["VALOR_SALARIAL"] : item["VALOR_NO_SALARIAL"] || 0,
			is_salarial: item["VALOR_SALARIAL"] > 0,
			percent: item["PORCENTAJE"] || undefined,
			value_type: item["PORCENTAJE"] ? "percentage" : "monetary",
			quantity: qty,
		};
		if (nominaItem["description"].includes("INCAPACIDAD")) {
			if (item["TIPO_DE_INCAPACIDAD"] == "COMUN") nominaItem["description"] = "Incapacidad Enfermedad General";
			else if (item["TIPO_DE_INCAPACIDAD"] == "PROFESIONAL") nominaItem["description"] = "PROFESIONAL";
			else nominaItem["description"] = "Incapacidad Enfermedad Laboral";
		}
		if (!workers.find((w) => w.document === worker.document)) {
			workers.push(worker);
			nomina.push({
				number: `${item["PREFIJO"]}${item["NUMERO"]}`,
				paid_at: item["FECHA_PAGO"],
				date: moment(`${item["AÑO"]}-${item["MES"]}-01`),
				worker: worker,
				devengables: [],
				deducibles: [],
			});
		}
		const nominaSelected = nomina.find((n) => n.worker.document === worker.document);
		if (item["TIPO"] === "DEVENGADO") nominaSelected.devengables.push(nominaItem);
		else nominaSelected.deducibles.push(nominaItem);
	}
	for (const item of nomina) {
		item.devengables.forEach((dev) => {
			if (dev.quantity > 1) {
				dev.start_date = moment.utc(item["FECHA"]).startOf("month").format("YYYY-MM-DD");
				dev.end_date = moment.utc(item["FECHA"]).endOf("month").format("YYYY-MM-DD");
				dev.days = dev.quantity;
			}
		});
	}
	return { nomina, workers };
};

const replacefromDataico = (code) => {
	const reverseMap = Object.assign({}, ...Object.entries(dictionary_dataico).map(([a, b]) => ({ [b]: a })));
	return (
		reverseMap[
			`${code}`
				.trim()
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toUpperCase()
				.replaceAll(" ", "_")
		] || code
	).replaceAll("_", " ");
};

export const getBaseSalarial = (devengables = []) => {
	let base = 0;
	for (const dev of devengables) {
		if (dev.is_salarial && !["Auxilio de Transporte"].includes(dev.description)) {
			base += dev.value * dev.quantity;
		}
	}
	return base;
};

export const getBase = (devengables = []) => {
	let base = 0;
	for (const dev of devengables) {
		if (dev.is_salarial) {
			base += dev.value * dev.quantity;
		}
	}
	return base;
};

export default {
	getPriceBasedOnQuantity(product, quantity, defaultPrice) {
		if (product.extra && product.extra.length > 0) {
			for (var i = 0; i < product.extra.length; i++) {
				var field = product.extra[i];
				if (field.key && field.key.startsWith("combo ")) {
					var [min, max] = field.key.replace("combo ", "").split("-");
					if (quantity >= min && quantity <= max) {
						return field.value;
					}
				}
			}
		}
		if (defaultPrice) {
			return defaultPrice;
		}
		return product._precio;
	},
};
